import React, { useState, useEffect } from 'react'
import { Image, Navbar, NavbarBrand, Divider, NavbarContent, CardBody, Card } from '@nextui-org/react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { map, find, split } from 'lodash-es'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import CustomAvatar from '../CustomAvatar'
import Button from '@/components/Button'
import IconFont from '@/components/IconFonts'
import { notify } from '@/utils/crypto'
import { useImage, useLoraList, useModelList } from '@/swr/useImage'
import { MIAOBI_FONTS, MIAOBI_STYLE } from '@/consts'

const ImageInfo = ({ image_id, images }) => {
  const router = useRouter()

  const { data, isValidating, mutate } = useImage(image_id)
  const [prev, setPrev] = useState(null)
  const [next, setNext] = useState(null)
  const { data: loraList } = useLoraList()
  const { data: modelList } = useModelList()

  const image_lora_list = data?.lora_model?.split(',')
  const image_lora_strength_list = data?.lora_strength?.split(',')
  const model_info = modelList?.find((v) => v.model_id === data?.model_id)
  // 结合image_lora_list和image_lora_strength_list和loraList，一一对应
  const show_lora_list = loraList?.filter((v) => image_lora_list?.includes(v?.lora_model_id)).map((v, i) => ({ ...v, strength: image_lora_strength_list[i] }))

  // 在需要时手动触发数据重新验证
  useEffect(() => {
    mutate()
    // eslint-disable-next-line
  }, [image_id])

  useEffect(() => {
    // 防止页面滚动穿透
    const originalOverflow = window.getComputedStyle(document.body).overflow
    if (image_id) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      // 页面滚动恢复原状
      document.body.style.overflow = originalOverflow
    }
  }, [image_id])
  const [path] = split(router.asPath, '?')

  const imageIds = map(images, (v) => v.id)

  useEffect(() => {
    const index = imageIds?.indexOf(image_id)
    if (index !== -1) {
      setPrev(index > 0 ? imageIds[index - 1] : null)
      setNext(index < imageIds?.length - 1 ? imageIds[index + 1] : null)
    }
  }, [imageIds, image_id])

  const [isExpandedPositive, setIsExpandedPositive] = useState(false)
  const [isExpandedNegative, setIsExpandedNegative] = useState(false)

  const toggleTextPositive = () => setIsExpandedPositive(!isExpandedPositive)
  const toggleTextNegative = () => setIsExpandedNegative(!isExpandedNegative)

  const handleKeyDownPositive = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      toggleTextPositive()
    }
  }

  const handleKeyDownNegative = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      toggleTextNegative()
    }
  }

  useEffect(() => {
    const isRefreshed = localStorage.getItem('isRefreshed')

    if (isRefreshed) {
      // If the page was refreshed, redirect to the new page
      localStorage.removeItem('isRefreshed') // Reset the flag
      if (router.query.image_id) router.replace(`/image/${image_id}`)
    } else {
      // Set the flag to indicate the page has been loaded
      localStorage.setItem('isRefreshed', 'true')
    }

    // Optional: Clean up the flag when the component unmounts
    return () => {
      localStorage.removeItem('isRefreshed')
    }
  }, [image_id, router])

  return (
    <div className={`${image_id ? 'fixed inset-0 z-50 block bg-background' : 'hidden'}`}>
      <div className="relative h-full w-full">
        <Navbar isBordered maxWidth="full">
          <NavbarContent justify="start">
            <NavbarBrand>
              <NavbarBrand onClick={() => router.push('/')} className="flex items-center gap-3">
                <Image src="/logo.png" width={40} height={40} alt="" radius="full" />
                <p className="cursor-pointer font-bold text-inherit">Ground AI</p>
              </NavbarBrand>
            </NavbarBrand>
          </NavbarContent>
          <NavbarContent justify="end">
            <Button
              isIconOnly
              radius="full"
              onClick={() => {
                if (router.asPath.includes(`/image`)) {
                  router.push('/gallery')
                } else {
                  router.push(path, undefined, { shallow: true })
                }
              }}
            >
              <IconFont icon="icon-close" className="text-xs font-bold" />
            </Button>
          </NavbarContent>
        </Navbar>
        {prev && (
          <div className="absolute left-2 top-1/2 z-50">
            <Button isIconOnly radius="full" onClick={() => router.push(`${path}?image_id=${prev}`, undefined, { shallow: true })}>
              <IconFont icon="icon-arrow-left" />
            </Button>
          </div>
        )}
        {next && (
          <div className="absolute right-2 top-1/2 z-50">
            <Button isIconOnly radius="full" onClick={() => router.push(`${path}?image_id=${next}`, undefined, { shallow: true })}>
              <IconFont icon="icon-arrow-right" />
            </Button>
          </div>
        )}
        <div className="mx-auto flex h-mcontent w-full flex-col overflow-auto lg:h-content lg:flex-row">
          <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-6">
            <Image alt="" className="m-auto h-full max-h-[700px] w-full max-w-[600px] object-contain" src={data?.cdn_img_url} isBlurred isLoading={isValidating} />
          </div>
          <div className="h-full w-full max-w-full rounded-none border-l border-solid border-divider p-6 lg:w-[500px] lg:overflow-auto">
            <div className="flex items-center gap-3">
              <CustomAvatar avatar={data?.author_avatar} width={40} height={40} onClick={() => router.push(`/user/${data?.user_id}`)} />
              <div className="cursor-pointer text-lg" onClick={() => router.push(`/user/${data?.user_id}`)} role="presentation">
                {data?.author_nickname}
              </div>
            </div>
            <div className="mt-6 flex w-full items-center justify-center gap-6">
              <Button
                variant="bordered"
                size="sm"
                startContent={<IconFont icon="icon-download" />}
                onClick={() => {
                  router.push(`${data?.cdn_img_url}?download/${data?.cdn_img_url.split('/').pop()}`)
                }}
              >
                下载图片
              </Button>
              <CopyToClipboard text={data?.cdn_img_url} onCopy={() => notify('链接已复制')}>
                <Button size="sm" variant="bordered" startContent={<IconFont icon="icon-link" />}>
                  复制链接
                </Button>
              </CopyToClipboard>
              <Button
                variant="bordered"
                size="sm"
                startContent={<IconFont icon="icon-sd" />}
                onClick={() => {
                  const baseParams = `?prompt=${data?.generate_type === 'jinshu' ? data?.jinshu?.prompt : data?.text_prompts}&negative_prompts=${data?.negative_prompts}&seed=${
                    data.seed
                  }&sampler=${data.sampler}&steps=${data.steps}&model_id=${data.model_id}&style_preset=${data.style_preset}&cfg_scale=${data.cfg_scale}&height=${
                    data.height
                  }&width=${data.width}`
                  navigator.clipboard.writeText(baseParams)
                  let url = ''
                  if (data?.generate_type === 'jinshu') {
                    url = `/creation/magic_quill${baseParams}`
                  } else if (data?.generate_type.includes('stable_diffusion_three')) {
                    url = `/creation/sd_three${baseParams}`
                  } else if (data?.generate_type.includes('midjourney')) {
                    url = `/creation/mj${baseParams}`
                  } else {
                    url = `/creation${baseParams}`
                  }
                  navigator.clipboard.writeText(baseParams)
                  router.push(url)
                }}
              >
                生成同款
              </Button>
            </div>
            <Divider className="my-3" />
            <div className="flex w-full items-start justify-between">
              <div className="flex w-full flex-col gap-0 break-all">
                <div className="flex items-center justify-between text-sm text-foreground/60">
                  <div>正向提示词</div>
                  <CopyToClipboard text={data?.generate_type === 'jinshu' ? data?.jinshu?.text_content : data?.text_prompts} onCopy={() => notify('复制成功')}>
                    <Button isIconOnly size="sm" variant="light">
                      <IconFont icon="icon-copy" className="text-xs" />
                    </Button>
                  </CopyToClipboard>
                </div>
                <div role="button" tabIndex={0} onClick={toggleTextPositive} onKeyDown={handleKeyDownPositive} className={`mt-1 ${!isExpandedPositive ? 'line-clamp-3' : ''}`}>
                  {data?.generate_type === 'jinshu' ? data?.jinshu?.text_content : data?.text_prompts}
                </div>
                {data?.negative_prompts && (
                  <>
                    <div className="mt-6 flex w-full items-center justify-between text-sm text-foreground/60">
                      <div>反向提示词</div>
                      <CopyToClipboard text={data?.negative_prompts} onCopy={() => notify('复制成功')}>
                        <Button isIconOnly size="sm" variant="light">
                          <IconFont icon="icon-copy" className="text-xs" />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    <div role="button" tabIndex={0} onClick={toggleTextNegative} onKeyDown={handleKeyDownNegative} className={`mt-1 ${!isExpandedNegative ? 'line-clamp-3' : ''}`}>
                      {data?.negative_prompts}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Divider className="my-3" />
            {model_info && (
              <div className="mb-6 flex flex-col justify-start" key={model_info?.model_id}>
                <p className="flex text-sm text-foreground/60">绘画模型</p>
                <Card className="mt-2 w-full">
                  <CardBody>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <Image src={model_info?.model_image} alt="" className="w-16" />
                        {model_info?.name}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
            {image_lora_list && image_lora_list.length > 0 && (
              <div className="mb-6">
                <p className="flex text-sm text-foreground/60">混合模型</p>
                {show_lora_list.map((v) => (
                  <div className="flex flex-col justify-start" key={v}>
                    <Card className="mt-2 w-full">
                      <CardBody>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-4">
                            <Image src={v?.model_image} alt="" className="w-16" />
                            {v?.name}
                          </div>
                          {v?.strength}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </div>
            )}
            <div className="mb-6 grid grid-cols-1 gap-4">
              {data?.jinshu?.prompt && (
                <div className="flex items-center justify-between">
                  <div className="text-sm text-foreground/60">绘画创意</div>
                  <p className="mt-1 text-small text-foreground">{data?.jinshu?.prompt}</p>
                </div>
              )}
              {data?.jinshu?.font_name && (
                <div className="flex items-center justify-between">
                  <div className="text-sm text-foreground/60">生成字体</div>
                  <p className="mt-1 text-small text-foreground">{find(MIAOBI_FONTS, (v) => v.font === data?.jinshu?.font_name).title}</p>
                </div>
              )}
              {data?.jinshu?.texture_style && (
                <div className="flex items-center justify-between">
                  <div className="text-sm text-foreground/60">生成风格</div>
                  <p className="mt-1 text-small text-foreground">{find(MIAOBI_STYLE, (v) => v.id === data?.jinshu?.texture_style).title}</p>
                </div>
              )}
              {data?.seed !== null && (
                <div className="flex items-center justify-between">
                  <div className="text-sm text-foreground/60">随机种子</div>
                  <p className="mt-1 text-small text-foreground">{data?.seed}</p>
                </div>
              )}
              {data?.sampler !== null && (
                <div className="flex items-center justify-between">
                  <div className="text-sm text-foreground/60">采样器</div>
                  <p className="mt-1 text-small text-foreground">{data?.sampler}</p>
                </div>
              )}
              {data?.steps !== null && (
                <div className="flex items-center justify-between">
                  <div className="text-sm text-foreground/60">采样步数</div>
                  <p className="mt-1 text-small text-foreground">{data?.steps}</p>
                </div>
              )}
              {data?.style_preset !== null && (
                <div className="flex items-center justify-between">
                  <di className="text-sm text-foreground/60">风格</di>
                  <p className="mt-1 text-small text-foreground">{data?.style_preset?.includes('midjourney') ? 'midjourney' : data?.style_preset ? data?.style_preset : ''}</p>
                </div>
              )}
              {data?.created_at && (
                <div className="flex items-center justify-between">
                  <di className="text-sm text-foreground/60">创建时间</di>
                  <p className="mt-1 text-small text-foreground">{dayjs(data?.created_at).format('YYYY-MM-DD HH:mm')}</p>
                </div>
              )}
              {data?.cfg_scale !== null && (
                <div className="flex items-center justify-between">
                  <di className="text-sm text-foreground/60">相关性</di>
                  <p className="mt-1 text-small text-foreground">{data?.cfg_scale}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageInfo
