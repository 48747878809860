import React from 'react'

const IconFont = ({ icon, className, onClick }) => {
  return (
    <span className="anticon">
      <i className={`iconfont ${icon} ${className ?? ''}`} onClick={onClick} role="presentation" />
    </span>
  )
}

export default IconFont
