import { Image, Chip } from '@nextui-org/react'

const CustomAvatar = ({ avatar, width, height, isVip, text, onClick }) => {
  return (
    <div className="relative">
      <Image
        src={avatar}
        alt=""
        onClick={onClick}
        width={width}
        height={height}
        style={{ height, width }}
        className="cursor-pointer border-2 border-solid border-foreground/80"
        radius="full"
      />
      {text && isVip && (
        <Chip color="danger" className="absolute bottom-0 right-0 z-10 border-2 border-solid border-foreground/80 text-xs font-bold">
          {text}
        </Chip>
      )}
    </div>
  )
}

export default CustomAvatar
