import useSWR from 'swr'
import { get, getToken } from '@/utils/request'

const useImagePrice = () => {
  const getImagePrice = async () => {
    const res = await get(`v1/images/get_image_price`)
    return res
  }
  const { data, mutate } = useSWR(`image_price`, getImagePrice)
  return { data: data?.data ?? [], mutate }
}

const useEngine = () => {
  const getEngineList = async () => {
    const res = await get(`v1/engines/list`)
    return res
  }
  const { data, mutate } = useSWR(`engine_list`, getEngineList)
  return { data: data ?? [], mutate }
}

const useCategoryList = () => {
  const getCategoryList = async () => {
    const res = await get(`v1/models/fetch_category_list`)
    return res
  }
  const { data, mutate } = useSWR(`category_list`, getCategoryList)
  return { data: data?.data ?? [], mutate }
}

const useLoraList = () => {
  const getLoraList = async () => {
    const res = await get(`v1/models/fetch_lora_list`)
    return res
  }
  const { data, mutate } = useSWR(`lora_list`, getLoraList)
  return { data: data?.data ?? [], mutate }
}

const useModelList = () => {
  const getModelList = async () => {
    const res = await get(`v1/models/fetch_models_list`)
    return res
  }
  const { data, mutate } = useSWR(`model_list`, getModelList)
  return { data: data?.data ?? [], mutate }
}

const usePromptTip = () => {
  const getPromptTip = async () => {
    const res = await get(`v1/images/fetch_random_tip`)
    return res.data
  }
  const { data, mutate } = useSWR(getToken() && `prompt_tip`, getPromptTip)
  return { data: data ?? '', mutate }
}

const usePrompt = () => {
  const getRandomPrompt = async () => {
    const res = await get(`v1/prompt/generate_random_prompt`)
    return res.data
  }
  const { data, mutate } = useSWR(getToken() && `random_prompt`, getRandomPrompt)
  return { data: data ?? [], mutate }
}

const useAdminImages = (page, pageSize) => {
  const getAdminImages = async () => {
    const res = await get(`v1/admin/fetch_all_images?page=${page}&limit=${pageSize}`)
    return res
  }
  const { data, mutate } = useSWR(getToken() ? `admin_images` : undefined, () => getAdminImages())
  return { data: data?.data?.images ?? [], total: data?.data?.total, mutate }
}

const useMJImage = (job_id) => {
  const getMJImageStatus = async () => {
    const res = await get(`v1/images/fetch_mj_result?job_id=${job_id}`)
    return res
  }
  const { data, mutate } = useSWR(getToken() ? `mjimage` : undefined, () => getMJImageStatus())
  return { data: data?.data ?? {}, mutate }
}

const getMBImage = async (job_id) => {
  const res = await get(`v1/images/steam_jinshu_result?job_id=${job_id}`)
  return res
}

const useMBImage = (job_id) => {
  const { data, mutate } = useSWR(getToken() ? `mbimage` : undefined, () => getMBImage(job_id))
  return { data: data?.data ?? {}, mutate }
}

const useAllImages = (page, pageSize, user_id) => {
  const getAllImages = async () => {
    const res = await get(
      getToken() ? `v1/images/fetch_show_images?page=${page}&limit=${pageSize}` : `v1/images/fetch_show_images?page=${page}&limit=${pageSize}&user_id=${user_id}`
    )
    return res
  }

  const { data, mutate } = useSWR(getToken() ? `all_images` : undefined, () => getAllImages())
  return { data: data?.data ?? [], mutate }
}

const getTodayImages = async (page, pageSize) => {
  const res = await get(`v1/images/fetch_user_today_images?page=${page}&limit=${pageSize}`)
  return res
}

const useTodayImages = (page, pageSize) => {
  const { data, mutate } = useSWR(getToken() ? `today_images` : undefined, () => getTodayImages(page, pageSize))
  return { data: data?.data ?? [], mutate }
}

const useImage = (image_id) => {
  const getImageInfo = async () => {
    const res = await get(`v1/images/fetch_image_info?image_id=${image_id}`)
    if (res.code === 200) {
      return res.data
    }
    return {}
  }

  const { data, mutate, isValidating } = useSWR(image_id && `image_info`, () => getImageInfo())
  return { data: data ?? {}, mutate, isValidating }
}

export {
  useEngine,
  usePromptTip,
  useModelList,
  useLoraList,
  useCategoryList,
  useAdminImages,
  useMJImage,
  getMBImage,
  useMBImage,
  usePrompt,
  useAllImages,
  getTodayImages,
  useTodayImages,
  useImage,
  useImagePrice,
}
