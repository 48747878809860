const SORT_TYPE = [
  { type: 0, label: '最新生成' },
  { type: 1, label: '最先生成' },
  { type: 2, label: '最多点赞' },
]

const SDSTYLES = {
  'sd1.5': ['SD1.5', 'sd15.png'],
  sdxl: ['SDXL', 'sdxl.png'],
  'fofr-realvisxl-v3': ['真实风格', 'realvisxl.png'],
  'vibrant-glass': ['光线反射', 'vibrant-glass.jpeg'],
  'Ultar-lighting': ['超级光影', 'ultar_lighting.jpeg'],
  'fofr-sdxl-tron': ['科幻风格', 'sdxl-tron.png'],
  'analog-film': ['胶片摄影', 'delicate_detail.png'],
  anime: ['动画风格', 'masterpiece.png'],
  'digital-art': ['数字艺术', 'digital-art.png'],
  photographic: ['摄影风格', 'photographic.png'],
  'tile-texture': ['平铺纹理', 'tile-texture.png'],
  cinematic: ['电影风格', 'cinematic.png'],
  'fantasy-art': ['奇幻艺术', 'fantasy-art.png'],
  'low-poly': ['低多边形', 'low-poly.png'],
  'neon-punk': ['霓虹朋克', 'neon-punk.png'],
  'line-art': ['素描风格', 'line-art.png'],
  'modeling-compound': ['造型复合', 'modeling-compound.png'],
  'pixel-art': ['像素艺术', 'pixel-art.png'],
  origami: ['折纸艺术', 'origami.png'],
  '3d-model': ['3D模型', '3d-model.png'],
  'Delicate-details': ['精细细节', 'delicate_detail.png'],
}

const IMAGE_TO_IMAGE_STYLES = {
  bro623jbfe32: ['真实风格', 'realvisxl.png'],
  'fofr-sdxl-tron': ['科幻风格', 'sdxl-tron.png'],
  'analog-film': ['胶片摄影', 'delicate_detail.png'],
  anime: ['动画风格', 'masterpiece.png'],
  'digital-art': ['数字艺术', 'digital-art.png'],
  photographic: ['摄影风格', 'photographic.png'],
  'tile-texture': ['平铺纹理', 'tile-texture.png'],
  cinematic: ['电影风格', 'cinematic.png'],
  'fantasy-art': ['奇幻艺术', 'fantasy-art.png'],
  'low-poly': ['低多边形', 'low-poly.png'],
  'neon-punk': ['霓虹朋克', 'neon-punk.png'],
  'line-art': ['素描风格', 'line-art.png'],
  'modeling-compound': ['造型复合', 'modeling-compound.png'],
  'pixel-art': ['像素艺术', 'pixel-art.png'],
  origami: ['折纸艺术', 'origami.png'],
  '3d-model': ['3D模型', '3d-model.png'],
}

// 只需要 1:1 4:3 3:4 9:6 6:9 且需要在128-1024之间
const SHAPES = ['576,1024,9:16,竖屏', '512,512,1:1,常规', '1024,1024,1:1,高清', '1024,768,4:3,常用', '768,1024,3:4,常用', '1024,576,16:9,宽屏', '512,1024,1:2,竖屏']
const MIAOBI_SHAPES = ['1:1', '16:9', '9:16']

const SDMODELS = [
  ['stable-diffusion-v1-6', 'Stable Diffusion v1.6'],
  ['stable-diffusion-xl-1024-v1-0', 'Stable Diffusion XL v1.0'],
]

// export const SAMPLERS = ['DDIM', 'DDPM', 'K_DPMPP_2M', 'K_DPMPP_2S_ANCESTRAL', 'K_DPM_2', 'K_DPM_2_ANCESTRAL', 'K_EULER K_EULER_ANCESTRAL', 'K_HEUN', 'K_LMS']
const SAMPLERS = [
  { id: 'DDPMScheduler', description: 'DDPM Scheduler - 高质量图像生成' },
  { id: 'DDIMScheduler', description: 'DDIM Scheduler - 平衡速度与质量' },
  { id: 'PNDMScheduler', description: 'PNDM Scheduler - 精细控制图像细节' },
  { id: 'LMSDiscreteScheduler', description: 'LMS Discrete Scheduler - 优化图像清晰度' },
  { id: 'EulerDiscreteScheduler', description: 'Euler Discrete Scheduler - 精确控制图像细节' },
  { id: 'EulerAncestralDiscreteScheduler', description: 'Euler Ancestral Discrete Scheduler - 改善图像纹理' },
  { id: 'DPMSolverMultistepScheduler', description: 'DPM Solver Multistep Scheduler - 多步骤细节增强' },
  { id: 'HeunDiscreteScheduler', description: 'Heun Discrete Scheduler - 均衡质量与速度' },
  { id: 'KDPM2DiscreteScheduler', description: 'K-DPM2 Discrete Scheduler - 快速稳定输出' },
  { id: 'DPMSolverSinglestepScheduler', description: 'DPM Solver Singlestep Scheduler - 单步快速迭代' },
  { id: 'KDPM2AncestralDiscreteScheduler', description: 'K-DPM2 Ancestral Discrete Scheduler - 增强细节表现' },
  { id: 'UniPCMultistepScheduler', description: 'UniPC Multistep Scheduler - 统一精度控制' },
  { id: 'DDIMInverseScheduler', description: 'DDIM Inverse Scheduler - 逆向迭代优化' },
  { id: 'DEISMultistepScheduler', description: 'DEIS Multistep Scheduler - 动态探索迭代调度' },
  { id: 'IPNDMScheduler', description: 'IPNDM Scheduler - 高精度图像细节' },
  { id: 'ScoreSdeVeScheduler', description: 'Score Sde Ve Scheduler - 得分SDE变分调度' },
  { id: 'LCMScheduler', description: 'LCM Scheduler - 低成本维护调度' },
]

const MIAOBI_FONTS = [
  { font: 'dongfangdakai', title: '阿里妈妈东方大楷' },
  { font: 'puhuiti_m', title: '阿里巴巴普惠体' },
  { font: 'shuheiti', title: '阿里妈妈数黑体' },
  { font: 'jinbuti', title: '钉钉进步体' },
  { font: 'kuheiti', title: '站酷酷黑体' },
  { font: 'kuaileti', title: '站酷快乐体' },
  { font: 'wenyiti', title: '站酷文艺体' },
  { font: 'logoti', title: '站酷小薇LOGO体' },
  { font: 'cangeryuyangti_m', title: '站酷仓耳渔阳体' },
  { font: 'siyuansongti_b', title: '思源宋体' },
  { font: 'siyuanheiti_m', title: '思源黑体' },
  { font: 'fangzhengkaiti', title: '方正楷体' },
]

const MIAOBI_STYLE = [
  { id: 'material', title: '立体材质' },
  { id: 'scene', title: '场景融合' },
]

const VIP_LEVEL = {
  10: '体验会员',
  11: '标准会员',
  100: '标准会员',
  101: '高级会员',
  102: '超级会员',
  200: '季度标准会员',
  201: '季度高级会员',
  202: '季度超级会员',
  300: '年度标准会员',
  301: '年度高级会员',
  302: '年度超级会员',
}

const EXGOODS = {
  10: {
    origin_price: 9.9,
    desc: '满足基本对话绘画需求',
    features: ['支持AI对话', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复'],
  },
  11: {
    origin_price: 45,
    desc: '满足基本对话绘画需求',
    features: ['支持AI对话', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复'],
  },
}

const GOODS = {
  10: {
    origin_price: 9.9,
    desc: '满足基本对话绘画需求',
    features: ['支持AI对话', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复'],
  },
  11: {
    origin_price: 45,
    desc: '满足基本对话绘画需求',
    features: ['支持AI对话', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复'],
  },
  100: {
    origin_price: 45,
    desc: '满足基本对话绘画需求',
    features: ['支持AI对话', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复'],
  },
  101: {
    origin_price: 85,
    desc: '多数人的选择',
    features: ['支持AI对话', '支持GPT4', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复', '支持妙笔', '支持Stable Diffusion 3'],
  },
  102: {
    origin_price: 160,
    desc: '更多特色功能开放使用',
    features: ['支持AI对话', '支持GPT4', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复', '支持妙笔', '支持Stable Diffusion 3'],
  },
  200: {
    origin_price: 121.5,
    desc: '满足基本对话绘画需求',
    features: ['支持AI对话', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复'],
  },
  201: {
    origin_price: 229.5,
    desc: '多数人的选择',
    features: ['支持AI对话', '支持GPT4', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复', '支持妙笔', '支持Stable Diffusion 3'],
  },
  202: {
    origin_price: 432,
    desc: '更多特色功能开放使用',
    features: ['支持AI对话', '支持GPT4', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复', '支持妙笔', '支持Stable Diffusion 3'],
  },
  300: {
    origin_price: 432,
    desc: '满足基本对话绘画需求',
    features: ['支持AI对话', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复'],
  },
  301: {
    origin_price: 816,
    desc: '多数人的选择',
    features: ['支持AI对话', '支持GPT4', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复', '支持妙笔', '支持Stable Diffusion 3'],
  },
  302: {
    origin_price: 1536,
    desc: '更多特色功能开放使用',
    features: ['支持AI对话', '支持GPT4', '支持文生图、图生图', '文生图可同时创作4个作品', '支持高清修复', '支持妙笔', '支持Stable Diffusion 3'],
  },
}

const MJ_MODE_SPEED = [
  ['relax', '放松模式', '放松模式响应一般在10分钟内，整体成功率在90%以上', 10],
  ['fast', '快速模式', '快速模式响应时间在100秒以内，整体成功率超过95%', 15],
  ['turbo', '极速模式', '极速模式响应时间在60秒内,整体成功率超过99%', 20],
]

export { SORT_TYPE, SDSTYLES, IMAGE_TO_IMAGE_STYLES, SHAPES, MIAOBI_SHAPES, SDMODELS, SAMPLERS, MIAOBI_FONTS, MIAOBI_STYLE, VIP_LEVEL, EXGOODS, GOODS, MJ_MODE_SPEED }
